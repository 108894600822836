<template>
  <div>
    <!-- Tabs -->
    <v-tabs
      color="secondary"
      :value="currentTab"
      @change="tabChange"
    >
      <v-tab
        v-for="(tab, i) in tabList"
        :key="i"
        :href="tab.href"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <BaseConfirmModal
      :value="showUnsaved"
      :title="unsavedContentTitle"
      :text="unsavedContentText"
      declineText="Cancel"
      @clicked="unsavedModalConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import baseTabMixin from '@/components/mixins/workspace/baseTabMixin';

export default {
  name: 'ServerTabs',
  data: () => ({
    baseTabs: [
      {
        title: 'Oauth Users',
        href: '#tab-users',
        link: 'tab-users',
        component: 'ServerUsersEdit',
      },
      {
        title: 'Client Credentials',
        href: '#tab-creds',
        link: 'tab-creds',
        component: 'ServerCredsEdit',
      },
    ],
    superTabs: [
      {
        title: 'Global Applications',
        href: '#tab-global',
        link: 'tab-global',
        component: 'ServerAppsEdit',
      },
      {
        title: 'Biometric Algorithms',
        href: '#tab-algs',
        link: 'tab-algs',
        component: 'ServerBiometricsEdit',
      },
      {
        title: 'Biometric Groups',
        href: '#tab-groups',
        link: 'tab-groups',
        component: 'ServerGroupsEdit',
      },
      {
        title: 'Liveness',
        href: '#tab-liveness',
        link: 'tab-liveness',
        component: 'ServerLivenessEdit',
      },
    ],
    showUnsaved: false,
  }),
  computed: {
    ...mapGetters('tabs', {
      currentTab: 'getSelected',
    }),
    ...mapGetters('app', ['roleAtLeastSuper', 'getUnsaved']),
    tabList() {
      if (!this.roleAtLeastSuper) {
        return this.baseTabs;
      }
      return this.superTabs.concat(this.baseTabs);
    },
  },
  components: {
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  methods: {
    ...mapActions('tabs', {
      setCurrentTab: 'setSelected',
    }),
  },
  mixins: [baseTabMixin],
};
</script>
